.achievements {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.achievements>* {
  vertical-align: middle;
}

.title {
  font-weight: 800 !important;
}

.link > .icon {
  margin-left: .5rem;
  vertical-align: bottom;
}

.info {
  white-space: pre-wrap;
  margin-left: 1rem;
}

@media print {
  .achievements {
    align-items: start;
  }

  .icon {
    display: none !important;
  }
}