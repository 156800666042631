.infoBox {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
}

.infoBoxPrint {
  display: none;
}

.descriptionPrint {
  display: none;
}

.infoField {
  font-weight: 800 !important;
}

.infoField:nth-of-type(odd) {
  text-align: end;
}

.profileImage {
  width: 50% !important;
  height: 50% !important;

  max-width: 10rem;
  max-height: 10rem;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;

  border: 2px solid white;
}

.contact {
  display: none;
}

@media print {
  .general > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .name {
    font-size: 1.75rem !important;
  }

  .profileImage {
    width: 80% !important;
    height: 80% !important;
  }

  .infoBox {
    display: none;
  }

  .infoBoxPrint {
    display: inherit;
  }

  .descriptionPrint {
    display: inherit;
  }

  .contact {
    display: inherit;
  }
}