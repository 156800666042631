@media print {
  .cv {
    display: none;
  }

  .printButton {
    display: none !important;
  }
}

.cv>* {
  margin-bottom: 4rem;
}

.printButton {
  position: fixed !important;
  bottom: 1.5rem;
  right: 1.5rem;
}