.app-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

:root {
  --content-max-width: 80vw;
}