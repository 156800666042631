.certification {
  white-space: nowrap;
}

.certification>* {
  display: inline-block;
  vertical-align: middle;
}

.title {
  font-weight: 800 !important;
}

.link > .icon {
  margin-left: .5rem;
  vertical-align: bottom;
}

.info {
  white-space: pre-wrap;
  margin-left: 1rem;
}

@media print {
  .icon {
    display: none !important;
  }
}