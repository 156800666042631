.hobbyList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.hobbyList > * {
  flex: 1 1 25%;
  text-align: center;
  white-space: pre-wrap;
  margin: 1rem;
  max-width: 10rem;
}

.hobby>* {
  margin-bottom: 0.5rem;
}

.hobbyIcon {
  width: 60px !important;
  height: 60px !important;
}

@media print {
  .hobbyIcon {
    height: 50px !important;
    width: 50px !important;
  }
  .hobbyDescription {
    display: none;
  }

  .hobbyList {
    justify-content: start;
  }

  .hobbyList > * {
    flex: 0 0 50px;
    margin: 0.5rem;
  }
}