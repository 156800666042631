.education {
  margin-bottom: 2rem;
}

.iconless {
  padding-left: 2.25rem;
}

@media print {
  .education {
    margin-bottom: 1rem;
  }
}