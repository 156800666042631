.skills {
  margin-bottom: 3rem;
}

.skillBadge {
  vertical-align: middle;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid grey;
  display: inline-block;
}

.header {
  margin-bottom: 1.5rem;
  vertical-align: middle;
}

.header>.preText {
  margin-right: .5rem;
}

.header>* {
  line-height: 1.5 !important;
  vertical-align: middle;
}

.selectionHint {
  text-align: center;
}

.selectionHint>* {
  vertical-align: middle;
  margin-right: .5rem;
}