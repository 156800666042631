.parallaxPlaceholder {
  height: 800px;
}

.bryceCanyon {
  transform: translateX(0);
}

.separatorImages {
  min-width: 100vw;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .bryceCanyon{
    transform: translateX(20%);
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .bryceCanyon{
    transform: translateX(10%);
  }
}

