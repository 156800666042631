.aboveTheFold {
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.socialLinks>* {
  padding: 8px !important
}

.description {
  font-style: italic;
  margin: 0 0 2rem 0;
  width: 400px;
  max-width: 80vw;
  text-align: center;
}