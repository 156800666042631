.nav-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.links {
  display: flex;
  flex-direction: row;

  list-style-type: none;
}

.links>* {
  margin: 0 16px 0 0;
}

.menu {
  display: none;
}

.mobile .links {
  display: none;
}

.mobile .links.open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: 100vh;
  margin: 0px;
}

.mobile .links>* {
  margin: 8px 0;
}

.mobile .menu {
  display: block;
}